import React from 'react';
import { Wifi, WifiOff } from 'lucide-react';

const ConnectionStatus = ({ connected }) => (
  <div className={`px-3 py-1 rounded-full text-sm flex items-center gap-2 ${
    connected ? 'bg-green-950 text-green-400' : 'bg-red-950 text-red-400'
  }`}>
    {connected ? (
      <>
        <Wifi className="w-4 h-4" />
        <span>Connected</span>
      </>
    ) : (
      <>
        <WifiOff className="w-4 h-4" />
        <span>Disconnected</span>
      </>
    )}
  </div>
);

export default ConnectionStatus;