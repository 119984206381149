import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Calendar, Clock, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import { getRecentPosts } from '../lib/contentful';

const BlogCard = ({ post }) => (
  <motion.div 
    className="relative group h-full"
    whileHover={{ y: -2 }}
  >
    <div className="h-full bg-gradient-to-br from-gray-900 to-blue-950 rounded-lg border border-blue-200/10 overflow-hidden transition-all duration-300 hover:border-blue-400/30">
      <Link to={`/blog/${post.slug}`}>
        <div className="p-8">
          <div className="text-sm text-blue-400 mb-4">{post.category}</div>
          <h3 className="text-xl font-light text-white mb-4 group-hover:text-blue-300 transition-colors">
            {post.title}
          </h3>
          <p className="text-blue-100/70 font-light leading-relaxed mb-6">
            {post.excerpt}
          </p>
          <div className="flex items-center gap-4 text-sm text-blue-200/60">
            <div className="flex items-center gap-2">
              <Calendar className="w-4 h-4" />
              {post.date}
            </div>
            <div className="flex items-center gap-2">
              <Clock className="w-4 h-4" />
              {post.readTime} min read
            </div>
          </div>
        </div>
      </Link>
    </div>
  </motion.div>
);

const BlogSection = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadPosts = async () => {
      try {
        const fetchedPosts = await getRecentPosts(3);
        setPosts(fetchedPosts);
      } catch (error) {
        console.error('Error loading posts:', error);
      } finally {
        setLoading(false);
      }
    };

    loadPosts();
  }, []);

  if (loading) {
    return <div className="py-24 bg-[#0A1017]">Loading...</div>;
  }

  return (
    <section className="py-24 bg-[#0A1017]">
      <div className="container mx-auto px-6">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="max-w-3xl mx-auto text-center mb-16"
        >
          <h2 className="text-4xl font-light text-white mb-6">
            Latest Insights
          </h2>
          <p className="text-xl text-blue-100/70 font-light">
            Expert perspectives on enterprise AI strategy and implementation
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
          {posts.map((post, index) => (
            <motion.div
              key={post.id}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <BlogCard post={post} />
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="text-center"
        >
          <Link 
            to="/blog"
            className="inline-flex items-center justify-center px-8 py-4 text-lg font-light text-white bg-white/5 hover:bg-white/10 rounded-md border border-white/10 transition-colors duration-300"
          >
            View All Articles
            <ChevronRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
          </Link>
        </motion.div>
      </div>
    </section>
  );
};

export default BlogSection;