import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

const formatTime = (timestamp) => {
  if (!timestamp) return '';
  
  const date = typeof timestamp === 'string' ? 
    new Date(timestamp) : 
    new Date(timestamp);

  return date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  });
};

const MessageItem = ({ message }) => {
  const isUser = message.type === 'user_message';
  
  // Get content based on message type
  const messageContent = isUser ? message.content : message.content || message.output;
  
  return (
    <div className={`flex ${isUser ? 'justify-end' : 'justify-start'}`}>
      <div className={`max-w-[85%] rounded-xl p-4 ${
        isUser ? 'bg-blue-600' : 'bg-[#1A1F2E]'
      }`}>
        <div className="prose prose-invert prose-sm max-w-none">
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            components={{
              p: ({ children }) => <p className="mb-2 last:mb-0">{children}</p>,
              a: ({ href, children }) => (
                <a href={href} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
                  {children}
                </a>
              ),
              code: ({ inline, children }) => (
                inline ? 
                <code className="bg-black/30 rounded px-1 py-0.5">{children}</code> :
                <pre className="bg-black/30 rounded p-3 overflow-x-auto">
                  <code>{children}</code>
                </pre>
              ),
              ul: ({ children }) => <ul className="list-disc pl-4 mb-2">{children}</ul>,
              ol: ({ children }) => <ol className="list-decimal pl-4 mb-2">{children}</ol>,
              li: ({ children }) => <li className="mb-1">{children}</li>,
            }}
          >
            {messageContent}
          </ReactMarkdown>
        </div>
        <div className="text-xs text-white/60 mt-2">
          {formatTime(message.createdAt)}
        </div>
      </div>
    </div>
  );
};

export default MessageItem;