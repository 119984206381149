import React, { useState, useEffect } from 'react';
import { useChatSession, useChatData, useChatInteract } from "@chainlit/react-client";
import ChatWindow from './ChatWindow';
import AITerminal from './AITerminal';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000';

const getInitialMessage = (section) => {
  const messages = {
    hero: "Hi! I'd like to learn more about how you can help transform our enterprise AI strategy.",
    testimonials: "Can you tell me more about your client success stories?",
    services: "What enterprise AI services do you offer?",
    'case-studies': "Could you share some examples of successful AI implementations?",
    approach: "Can you explain your implementation methodology?",
    team: "Tell me more about your leadership team.",
    network: "I'd like to learn about your expert network.",
    advisory: "Can you tell me about your advisory board?",
    'blog-section': "What are your latest insights on enterprise AI?",
    calendly: "I'm interested in scheduling an executive briefing."
  };
  return messages[section] || "Hi! I'm interested in learning more about your enterprise AI services.";
};

const ChatInterface = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const [connectionError, setConnectionError] = useState(null);
  const [hasInitialMessageBeenSent, setHasInitialMessageBeenSent] = useState(false);
  
  const { connect, disconnect } = useChatSession();
  const { loading, connected, error } = useChatData();
  const { sendMessage } = useChatInteract();

  // Track current section based on scroll position
  useEffect(() => {
    const sections = [
      'hero', 'testimonials', 'services', 'case-studies',
      'approach', 'team', 'network', 'advisory',
      'blog-section', 'calendly'
    ];
    
    const handleScroll = () => {
      const viewportMiddle = window.innerHeight / 2;
      let nearestSection = null;
      let smallestDistance = Infinity;

      sections.forEach(sectionId => {
        const element = document.getElementById(sectionId);
        if (element) {
          const rect = element.getBoundingClientRect();
          const sectionMiddle = rect.top + rect.height / 2;
          const distance = Math.abs(sectionMiddle - viewportMiddle);
          
          if (distance < smallestDistance) {
            smallestDistance = distance;
            nearestSection = sectionId;
          }
        }
      });

      if (nearestSection !== currentSection) {
        setCurrentSection(nearestSection);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();
    
    return () => window.removeEventListener('scroll', handleScroll);
  }, [currentSection]);

  // Handle scroll lock when chat is open
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : '';
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  // Handle automatic message sending after connection
  useEffect(() => {
    const sendInitialMessage = async () => {
      if (connected && isOpen && !hasInitialMessageBeenSent && !loading) {
        const message = getInitialMessage(currentSection);
        await sendMessage({ content: message }, []);
        setHasInitialMessageBeenSent(true);
      }
    };

    sendInitialMessage();
  }, [connected, isOpen, currentSection, hasInitialMessageBeenSent, loading, sendMessage]);

  const handleOpen = async () => {
    if (isConnecting) return;
    
    setIsConnecting(true);
    setConnectionError(null);
    setHasInitialMessageBeenSent(false);
    
    try {
      const response = await fetch(`${BACKEND_URL}/custom-auth`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ section: currentSection }),
        credentials: 'include'
      });
      
      if (!response.ok) {
        throw new Error('Failed to authenticate with the server');
      }
      
      const { token } = await response.json();
      
      await connect({
        accessToken: `Bearer ${token}`, 
      });

      setIsOpen(true);
    } catch (error) {
      console.error('Chat initialization failed:', error);
      setConnectionError(error instanceof Error ? error.message : 'Failed to initialize chat');
    } finally {
      setIsConnecting(false);
    }
  };

  const handleClose = async () => {
    setIsOpen(false);
    setHasInitialMessageBeenSent(false);
    try {
      await disconnect();
    } catch (error) {
      console.error('Failed to disconnect chat:', error);
    }
  };

  return (
    <div className="fixed inset-0 pointer-events-none z-[100]">
      <div className="absolute inset-0 w-full h-full flex items-end justify-end">
        <div className="mb-32">
          {isOpen ? (
            <ChatWindow 
              onClose={handleClose} 
              currentSection={currentSection}
              loading={loading}
              connected={connected}
              error={error}
            />
          ) : (
            <AITerminal 
              onClick={handleOpen}
              isConnecting={isConnecting}
              error={connectionError}
              currentSection={currentSection}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatInterface;