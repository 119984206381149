import React from 'react';
import { motion } from 'framer-motion';

const TestimonialCard = ({ quote, author, role, type = "client" }) => (
  <div className={`
    bg-[#0B1426]/50 rounded-xl p-8 h-full 
    border border-blue-900/10 hover:border-blue-700/20 transition-colors duration-300 border-l-4 border-l-blue-500
  `}>
    <div className="flex flex-col h-full">
      <svg 
        className="text-blue-500/20 h-8 w-8 mb-6" 
        fill="currentColor" 
        viewBox="0 0 24 24"
      >
        <path d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z" />
      </svg>
      
      <p className="text-gray-300 text-lg font-light leading-relaxed flex-grow mb-6">
        {quote}
      </p>
      
      <div>
        <p className="text-white font-medium">{author}</p>
        <p className="text-blue-400 font-light text-sm mt-1">{role}</p>
      </div>
    </div>
  </div>
);

const Testimonials = () => {
  const partnerTestimonials = [
    {
      quote: "I am a software architect who has been developing solutions for clients worldwide for over 40 years. Recently I have worked closely with Christian Adib on several projects incorporating emerging technologies surrounding generative AI. Christian is efficient, motivated, knowledgeable, and dedicated, as well as being a pleasure to work with. I am particularly impressed with his ability to articulate solutions by quickly building demonstrable, working prototypes, and the way he works with and guides a team to iterate toward production quality code. The results we produce together continue to impress clients — I would give Christian my strongest recommendation.",
      author: "GH",
      role: "Technology Partner & Software Architect",
      type: "partner"
    }
  ];

  const clientTestimonials = [
    {
      quote: "Working with Christian was a game-changer for our business. His deep understanding of AI technologies and their practical application helped us focus our initiatives and uncover new opportunities. The insights and strategies he provided were not only innovative but also tailored specifically to our needs. I highly recommend Christian for anyone looking to leverage AI to drive growth and efficiency.",
      author: "John Wass",
      role: "CEO, Profit Isle",
      type: "client"
    },
    {
      quote: "Christian is a go-to-expert and a one-stop-shop for all things Generative AI considered. We hired Christian to help us with AI Agent development and implementation for specialized knowledge professional practice, and we found Christian to be incredibly resourceful. The AI domain changes wildly fast and Christian was able to distill our requirements to a remarkable set of discrete deliverables that demonstrated durable value capture. He was able to exercise various skill sets ranging from AI engineering to Software engineering to demonstrate how an AI agent could utilize the power of AI and automate countless professional hours while maintaining a high bar for customer satisfaction",
      author: "SP",
      role: "Product Management Executive",
      type: "client"
    },
    {
      quote: "We engaged Fulkerson Advisors to automate a proprietary model for tracking changes across S&P U.S. indexes. The team exceeded our expectations at every stage—from understanding our requirements to conducting statistical analysis, as well as designing and executing the solution. The project was delivered on time with exceptional quality and flawless functionality. We would gladly work with this team again for future projects in this space and highly recommend them to any financial institution seeking automation and enhanced efficiency through generative AI.",
      author: "SG",
      role: "Chief Investment Officer, $55 Bn US Pension Fund",
      type: "client"
    },
    {
      quote: "Christian's technical expertise initially drew us in, but his strategic mindset, flexibility, humility, and commitment have made him irreplaceable. He combines rare technical mastery with genuine partnership—making him the kind of partner you wish you could hire full-time. His willingness to listen first and execute second means he truly understands my vision and the mission of the company.",
      author: "Marjorie",
      role: "CEO and Founder, ALEE",
      type: "client"
    },
    {
      quote: "Christian created an AI-powered MTL WHAT-WHO-WHY Sandbox that far exceeded my expectations in record time. He quickly intuited my needs, iterated efficiently, and delivered a scalable, user-friendly online tool in just a few days. Everyone I've shown his work to has been blown away and requested ongoing access. His ability to transform ideas into impactful, practical solutions, coupled with exceptional follow-through and responsiveness, is unmatched in my 40 years of experience working with tech experts.",
      author: "Peter DiGiammarino",
      role: "Founder and Managing Partner, IntelliVen",
      type: "client"
    }
  ];

  return (
    <section className="py-24 bg-[#0A1017]">
      <div className="container mx-auto px-4 md:px-16">
        <h2 className="text-3xl md:text-4xl text-white font-light mb-16 text-center">
          What Our Network Says
        </h2>

        {/* Partners Section */}
        <div className="mb-20">
          <h3 className="text-blue-400 text-sm uppercase tracking-wider font-light mb-8">
            Technology Partners
          </h3>
          <div className="grid grid-cols-1 gap-8">
            {partnerTestimonials.map((testimonial, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <TestimonialCard {...testimonial} />
              </motion.div>
            ))}
          </div>
        </div>

        {/* Clients Section */}
        <div className="mb-20">
          <h3 className="text-blue-400 text-sm uppercase tracking-wider font-light mb-8">
            Clients
          </h3>
          <div className="grid grid-cols-1 gap-8">
            {clientTestimonials.map((testimonial, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <TestimonialCard {...testimonial} />
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;