import { useState, useEffect } from 'react';

export const useViewportHeight = () => {
  const [height, setHeight] = useState(window.innerHeight);
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const visualViewportHeight = window.visualViewport?.height || window.innerHeight;
      const heightDiff = window.innerHeight - visualViewportHeight;
      setKeyboardHeight(heightDiff > 150 ? heightDiff : 0);
      setHeight(window.innerHeight);
    };

    window.visualViewport?.addEventListener('resize', handleResize);
    window.visualViewport?.addEventListener('scroll', handleResize);
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    return () => {
      window.visualViewport?.removeEventListener('resize', handleResize);
      window.visualViewport?.removeEventListener('scroll', handleResize);
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  return { height, keyboardHeight };
};

// Add this export for the keyboard height hook
export const useKeyboardHeight = () => {
  const { keyboardHeight } = useViewportHeight();
  return keyboardHeight;
};