import { useCallback } from 'react';

export const useScrollToBottom = (ref) => {
  const scrollToBottom = useCallback(() => {
    if (ref.current) {
      // Use requestAnimationFrame for smooth scrolling
      requestAnimationFrame(() => {
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      });
    }
  }, [ref]);

  return { scrollToBottom };
};