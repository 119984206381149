import React from 'react';
import { Sparkles } from 'lucide-react';

const ThinkingIndicator = () => (
  <div className="flex items-center gap-3">
    <div className="relative w-5 h-5">
      <Sparkles className="w-5 h-5 text-blue-400 animate-pulse" />
    </div>
    <div className="flex items-center gap-1.5">
      <div className="w-1 h-1 bg-blue-400/80 rounded-full animate-[bounce_1s_infinite_0ms]"></div>
      <div className="w-1 h-1 bg-blue-400/80 rounded-full animate-[bounce_1s_infinite_200ms]"></div>
      <div className="w-1 h-1 bg-blue-400/80 rounded-full animate-[bounce_1s_infinite_400ms]"></div>
    </div>
  </div>
);

export default ThinkingIndicator;