import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Calendar, Clock, ChevronLeft, User } from 'lucide-react';
import { Helmet } from 'react-helmet';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { getPostBySlug } from '../lib/contentful';

// Loading spinner component
const LoadingSpinner = () => (
  <div className="bg-[#0A1017] min-h-screen mt-20 flex items-center justify-center">
    <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500" />
  </div>
);

// Error message component
const ErrorMessage = ({ navigate }) => (
  <div className="bg-[#0A1017] min-h-screen mt-20 flex items-center justify-center">
    <div className="text-center">
      <h2 className="text-xl text-red-400 mb-4">Failed to load post</h2>
      <button
        onClick={() => navigate('/blog')}
        className="text-blue-400 hover:text-blue-300 transition-colors"
      >
        Return to Blog
      </button>
    </div>
  </div>
);

// Meta tags component
const MetaTags = ({ post }) => (
    <Helmet>
      <title>{`${post.title} | Fulkerson Advisors`}</title>
      <meta name="description" content={post.excerpt} />
      <meta property="og:title" content={post.title} />
      <meta property="og:description" content={post.excerpt} />
      <meta property="og:type" content="article" />
       {/* Add og:image only if there's a featured image */}
        {post.featuredImage && (
          <meta property="og:image" content={post.featuredImage} />
        )}
      <meta property="article:published_time" content={post.publishDate} />
      <meta property="article:author" content={post.author.name} />
      {post.tags?.map((tag, index) => (
        <meta property="article:tag" content={tag} key={`${tag}-${index}`} />
      ))}
    </Helmet>
  );

const BlogPost = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Rich text rendering options
  const renderOptions = {
    renderMark: {
      [MARKS.BOLD]: text => <strong className="font-semibold">{text}</strong>,
      [MARKS.ITALIC]: text => <em className="italic">{text}</em>,
      [MARKS.CODE]: text => (
        <code className="px-1 py-0.5 bg-gray-800 rounded font-mono text-sm">
          {text}
        </code>
      ),
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="mb-6 text-gray-300 leading-relaxed">{children}</p>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className="text-3xl font-light text-white mt-12 mb-6">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="text-2xl font-light text-white mt-12 mb-6">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className="text-xl font-light text-white mt-8 mb-4">{children}</h3>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className="mb-6 pl-6 list-disc text-gray-300 space-y-2">{children}</ul>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <ol className="mb-6 pl-6 list-decimal text-gray-300 space-y-2">{children}</ol>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
      [BLOCKS.QUOTE]: (node, children) => (
        <blockquote className="border-l-4 border-blue-500 pl-4 py-1 mb-6 text-gray-300 italic">
          {children}
        </blockquote>
      ),
      [BLOCKS.HR]: () => <hr className="my-8 border-blue-900/20" />,
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const { url, title, description, width, height } = node.data.target.fields.file;
        return (
          <div className="my-8">
            <img
              src={url}
              alt={description || title}
              className="w-full h-auto rounded-lg"
              width={width}
              height={height}
            />
            {description && (
              <p className="mt-2 text-sm text-gray-400 text-center">{description}</p>
            )}
          </div>
        );
      },
      [INLINES.HYPERLINK]: (node, children) => (
        <a
          href={node.data.uri}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-400 hover:text-blue-300 transition-colors"
        >
          {children}
        </a>
      ),
    },
  };

  useEffect(() => {
    const loadPost = async () => {
      try {
        setLoading(true);
        const response = await getPostBySlug(slug);
        
        if (!response) {
          navigate('/blog');
          return;
        }
        
        setPost({
          ...response,
          tags: typeof response.tags === 'string' ? [response.tags] : (response.tags || [])
        });
      } catch (err) {
        console.error('Error loading post:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    loadPost();
  }, [slug, navigate]);

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage navigate={navigate} />;
  if (!post) return null;


  const formattedDate = new Date(post.publishDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });

  return (
    <>
      <MetaTags post={post} />
      <div className="bg-[#0A1017] min-h-screen mt-20">
        {/* Navigation */}
        <nav className="container mx-auto px-6 py-8">
          <Link 
            to="/blog"
            className="inline-flex items-center text-blue-400 hover:text-blue-300 transition-colors duration-300"
          >
            <ChevronLeft className="w-5 h-5 mr-2" />
            Back to Blog
          </Link>
        </nav>

                {/* Featured Image */}
        {post.featuredImage && (
        <div className="relative w-full h-[400px] md:h-[800px] bg-gradient-to-br from-gray-900 via-black to-blue-950">
            <img
            src={post.featuredImage}
            alt={post.title}
            className="absolute inset-0 w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-b from-black/30 to-transparent"></div>
        </div>
        )}

        {/* Article Header */}
        <header className="bg-gradient-to-br from-gray-900 via-black to-blue-950 py-16">
          <div className="container mx-auto px-6">
            <div className="max-w-3xl mx-auto">
              <div className="text-sm text-blue-400 mb-4">
                {post.category}
              </div>
              <h1 className="text-4xl md:text-5xl font-light text-white mb-8 leading-tight">
                {post.title}
              </h1>
              
              <div className="flex flex-col md:flex-row md:items-center gap-4 md:gap-8">
                <div className="flex items-center gap-3">
                  <div className="p-2 bg-blue-500/10 rounded-full">
                    <User className="w-4 h-4 text-blue-300" />
                  </div>
                  <div>
                    <div className="text-white font-light">{post.author.name}</div>
                    <div className="text-sm text-blue-200/60">{post.author.role}</div>
                  </div>
                </div>

                <div className="flex items-center gap-4 text-sm text-blue-200/60">
                  <div className="flex items-center gap-2">
                    <Calendar className="w-4 h-4" />
                    {formattedDate}
                  </div>
                  <div className="flex items-center gap-2">
                    <Clock className="w-4 h-4" />
                    {post.readTime} min read
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        {/* Article Content */}
        <article className="container mx-auto px-6 py-16">
          <div className="max-w-3xl mx-auto">
            <div className="prose prose-invert prose-blue prose-lg max-w-none">
              {documentToReactComponents(post.content, renderOptions)}
            </div>

            {/* Tags */}
            {Array.isArray(post.tags) && post.tags.length > 0 && (
              <div className="mt-16 pt-8 border-t border-blue-900/20">
                <h3 className="text-sm text-blue-200/60 mb-4">Related Topics</h3>
                <div className="flex flex-wrap gap-2">
                  {post.tags.map((tag, index) => (
                    <span 
                      key={`${tag}-${index}`}
                      className="px-3 py-1 text-sm text-blue-300 bg-blue-500/10 rounded-md"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>
        </article>
      </div>
    </>
  );
};

export default BlogPost;