import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { RecoilRoot } from 'recoil';
import { ChainlitAPI, ChainlitContext } from "@chainlit/react-client";
import './index.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000';
const CHAINLIT_API_URL = `${BACKEND_URL}/chainlit`
const apiClient = new ChainlitAPI(CHAINLIT_API_URL, 'webapp');

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ChainlitContext.Provider value={apiClient}>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </ChainlitContext.Provider>
  </React.StrictMode>
);
