import React, { useState, useEffect, useRef, useCallback } from 'react';
import { X, Send } from 'lucide-react';
import { useChatInteract, useChatMessages, useChatData } from "@chainlit/react-client";
import { useKeyboardHeight } from '../hooks/useViewportHeight';
import { useScrollToBottom } from '../hooks/useScrollToBottom';
import ConnectionStatus from './ConnectionStatus';
import ThinkingIndicator from './ThinkingIndicator';
import MessageItem from './MessageItem';

const ChatWindow = ({ onClose, currentSection }) => {
  // State management
  const [inputValue, setInputValue] = useState('');
  const [showThinking, setShowThinking] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [pendingMessages, setPendingMessages] = useState([]);
  
  // Refs
  const messagesEndRef = useRef(null);
  const textareaRef = useRef(null);
  const thinkingTimeoutRef = useRef(null);
  const chatContainerRef = useRef(null);
  
  // Custom hooks
  const { sendMessage } = useChatInteract();
  const { messages: apiMessages } = useChatMessages();
  const { loading, connected } = useChatData();
  const keyboardHeight = useKeyboardHeight();
  const { scrollToBottom } = useScrollToBottom(messagesEndRef);

  // Textarea height adjustment function - moved to top
  const adjustTextareaHeight = useCallback(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = 
        Math.min(textareaRef.current.scrollHeight, 100) + 'px';
    }
  }, []);

  // Handle message sending - now after adjustTextareaHeight
  const handleSend = useCallback(() => {
    const content = inputValue.trim();
    if (content && connected) {
      // Create pending message
      const newMessage = {
        id: crypto.randomUUID(),
        type: 'user_message',
        content: content,
        createdAt: new Date().toISOString(),
        name: 'user',
        isPending: true
      };

      // Add to pending messages
      setPendingMessages(prev => [...prev, newMessage]);

      // Send to API
      sendMessage({ content }, []);

      // Reset state
      setInputValue('');
      setShowThinking(true);
      clearTimeout(thinkingTimeoutRef.current);
      adjustTextareaHeight();
    }
  }, [inputValue, connected, sendMessage, adjustTextareaHeight]);

  // Handle keyboard events
  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  }, [handleSend]);

  // Process API messages
  const processApiMessage = useCallback((msg) => {
    const processed = [];

    // Handle direct content messages with steps
    if (msg.content && msg.steps) {
      processed.push({
        id: msg.id,
        type: msg.type || 'user_message',
        content: msg.content,
        createdAt: msg.createdAt,
        name: msg.name || 'user'
      });
    }

    // Handle direct messages without steps
    if (msg.content && !msg.steps) {
      processed.push({
        id: msg.id,
        type: msg.type || 'user_message',
        content: msg.content,
        createdAt: msg.createdAt,
        name: msg.name || 'user'
      });
    }

    // Handle messages with steps
    if (msg.steps) {
      msg.steps.forEach(step => {
        // Handle nested steps
        if (step.steps) {
          step.steps.forEach(nestedStep => {
            if (['user_message', 'assistant_message'].includes(nestedStep.type)) {
              processed.push({
                id: nestedStep.id,
                type: nestedStep.type,
                content: nestedStep.output || nestedStep.content,
                createdAt: nestedStep.createdAt || nestedStep.start || msg.createdAt,
                name: nestedStep.name
              });
            }
          });
        }
        // Handle direct steps
        else if (['user_message', 'assistant_message'].includes(step.type)) {
          processed.push({
            id: step.id,
            type: step.type,
            content: step.output || step.content,
            createdAt: step.createdAt || step.start || msg.createdAt,
            name: step.name
          });
        }
      });
    }

    return processed;
  }, []);

  // Combine and sort all messages
  const displayMessages = React.useMemo(() => {
    const allMessages = [
      ...pendingMessages,
      ...apiMessages.flatMap(msg => processApiMessage(msg))
    ];

    return allMessages.sort((a, b) => {
      const timeA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
      const timeB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
      return timeA - timeB;
    });
  }, [apiMessages, pendingMessages, processApiMessage]);

  // Effects
  useEffect(() => {
    if (loading) {
      setShowThinking(false);
      clearTimeout(thinkingTimeoutRef.current);
      // Remove pending messages when response is received
      setPendingMessages(prev => prev.filter(msg => !msg.isPending));
    }
  }, [loading]);

  useEffect(() => {
    scrollToBottom();
  }, [displayMessages, loading, showThinking, scrollToBottom]);

  useEffect(() => {
    if (chatContainerRef.current) {
      const height = keyboardHeight > 0 
        ? `calc(100% - ${keyboardHeight}px)` 
        : isMobile ? '100%' : '80%';
      chatContainerRef.current.style.height = height;
    }
  }, [keyboardHeight, isMobile]);

  useEffect(() => {
    adjustTextareaHeight();
  }, [inputValue, adjustTextareaHeight]);

  useEffect(() => {
    const handleResize = () => {
      const newIsMobile = window.innerWidth < 768;
      setIsMobile(newIsMobile);
      if (chatContainerRef.current && !keyboardHeight) {
        chatContainerRef.current.style.height = newIsMobile ? '100%' : '80%';
      }
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [keyboardHeight]);

  return (
    <div 
  ref={chatContainerRef}
  className={`
    pointer-events-auto flex flex-col bg-[#0A0F1C] text-white shadow-2xl w-full
    ${isMobile 
      ? 'fixed inset-0' 
      : 'fixed bottom-16 right-4 md:right-8 md:w-[400px] md:rounded-2xl h-[600px]'
    }
  `}
      style={keyboardHeight > 0 ? { height: `calc(100% - ${keyboardHeight}px)` } : undefined}
    >
      {/* Header */}
      <div className="flex items-center justify-between p-4 border-b border-white/10">
        <div className="flex items-center gap-3">
          <span className="text-xl font-medium">AI Assistant</span>
          <ConnectionStatus connected={connected} />
        </div>
        <button
          onClick={onClose}
          className="p-2 hover:bg-white/10 rounded-lg transition-colors"
        >
          <X className="w-5 h-5" />
        </button>
      </div>

      {/* Messages */}
      <div className="flex-1 overflow-y-auto">
        <div className="p-4 space-y-4">
          {displayMessages.map((msg) => (
            <MessageItem key={msg.id} message={msg} />
          ))}
          {(showThinking || loading) && (
            <div className="flex justify-start animate-fadeIn">
              <div className="max-w-[85%] rounded-xl py-3 px-4 bg-[#1A1F2E]">
                <ThinkingIndicator />
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
      </div>

      {/* Input */}
      <div className="p-4 border-t border-white/10">
        <div className="flex gap-2">
          <textarea
            ref={textareaRef}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Type your message..."
            disabled={!connected}
            rows={1}
            className="flex-1 bg-[#1A1F2E] rounded-xl p-3 resize-none outline-none 
                     placeholder:text-white/50 disabled:opacity-50 min-h-[44px] max-h-[100px]"
            style={{ fontSize: '16px' }}
          />
          <button
            onClick={handleSend}
            disabled={!inputValue.trim() || !connected}
            className="p-3 bg-blue-600 hover:bg-blue-700 disabled:opacity-50 
                     rounded-xl transition-colors"
          >
            <Send className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatWindow;