import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { sessionState, useChatSession } from "@chainlit/react-client";
import { useRecoilValue } from "recoil";

// Components
import Header from './components/Header';
import Hero from './components/Hero';
import Services from './components/Services';
import SuccessStories from './components/SuccessStories';
import Testimonials from './components/Testimonials';
import Approach from './components/Approach';
import About from './components/About';
import AdvisoryBoard from './components/AdvisoryBoard';
import Calendly from './components/Calendly';
import Footer from './components/Footer';
import NetworkSection from './components/NetworkSection';
import BlogSection from './components/BlogSection';
import BlogPage from './components/BlogPage';
import BlogPost from './components/BlogPost';
import ChatInterface from './components/ChatInterface';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';


// ScrollToTop component to handle navigation
const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

// Main content sections
const MainContent = () => {
  const sections = [
    { id: 'hero', Component: Hero },
    { id: 'testimonials', Component: Testimonials },
    { id: 'services', Component: Services },
    { id: 'case-studies', Component: SuccessStories },
    { id: 'approach', Component: Approach },
    { id: 'team', Component: About },
    { id: 'network', Component: NetworkSection },
    { id: 'advisory', Component: AdvisoryBoard },
    { id: 'blog-section', Component: BlogSection },
    { id: 'calendly', Component: Calendly }
  ];

  return (
    <main>
      {sections.map(({ id, Component }) => (
        <section key={id} id={id}>
          <Component />
        </section>
      ))}
    </main>
  );
};

// Main app content wrapper
const AppContent = () => {
  const [currentSection, setCurrentSection] = useState('default');

  // Function to determine current visible section
  const determineCurrentSection = () => {
    const sections = document.querySelectorAll('section[id]');
    let currentVisible = null;
    let smallestDistance = Infinity;

    sections.forEach(section => {
      const rect = section.getBoundingClientRect();
      const middle = window.innerHeight / 2;
      const distance = Math.abs(rect.top + rect.height / 2 - middle);
      
      if (distance < smallestDistance) {
        smallestDistance = distance;
        currentVisible = section.id;
      }
    });

    return currentVisible || 'default';
  };

  // Effect to track current section
  useEffect(() => {
    const handleScroll = () => {
      const newSection = determineCurrentSection();
      if (newSection !== currentSection) {
        setCurrentSection(newSection);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check
    
    return () => window.removeEventListener('scroll', handleScroll);
  }, [currentSection]);


  
  return (
    <div className="font-sans text-white bg-black">
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<MainContent />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/:slug" element={<BlogPost />} />
      </Routes>
      <ChatInterface currentSection={currentSection} />
      <Footer />
    </div>
  );
};

// Root App component
const App = () => {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
};

export default App;