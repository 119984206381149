import { createClient } from 'contentful';

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

export const getAllPosts = async () => {
    try {
      const response = await client.getEntries({
        content_type: 'blogPost',
        order: '-sys.createdAt',
        include: 1,
      });
  
      return response;
    } catch (error) {
      console.error('Error fetching posts:', error);
      throw error;
    }
  };

export const getRecentPosts = async (limit = 3) => {
  try {
    const response = await client.getEntries({
      content_type: 'blogPost',
      order: '-sys.createdAt',
      limit,
    });

    return response.items.map(mapPost);
  } catch (error) {
    console.error('Error fetching recent posts:', error);
    throw error;
  }
};

export const getPostBySlug = async (slug) => {
    try {
      const response = await client.getEntries({
        content_type: 'blogPost',
        'fields.slug': slug,
        include: 2,  // Important to resolve linked assets
        limit: 1,
      });
  
      if (!response.items.length) return null;
      return mapPost(response.items[0]);
    } catch (error) {
      console.error('Error fetching post:', error);
      throw error;
    }
  };

  const mapPost = (item) => {
    // Extract featured image URL - checking for proper path
    const featuredImageUrl = item.fields.featuredImage?.fields?.file?.url;
    
    return {
      id: item.sys.id,
      slug: item.fields.slug,
      title: item.fields.title,
      excerpt: item.fields.excerpt,
      content: item.fields.content,
      category: item.fields.category,
      // Add https: prefix if the URL is protocol-relative
      featuredImage: featuredImageUrl ? `https:${featuredImageUrl}` : null,
      author: item.fields.author?.fields || {
        name: 'Christian Adib',
        role: 'Founder and Managing Partner'
      },
      date: new Date(item.sys.createdAt).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }),
      readTime: item.fields.readTime || 5,
      tags: item.fields.tags || [],
      publishDate: item.sys.createdAt,
    };
  };

export default client;