import React from 'react';
import { FileText, Users, Award, BookOpen } from 'lucide-react';

const ExpertiseCard = ({ icon: Icon, title, description }) => (
  <div className="bg-[#0B1426]/50 rounded p-6 h-full border border-blue-900/10 hover:border-blue-700/20 transition-colors duration-300">
    <Icon className="text-blue-400 w-6 h-6 mb-4" />
    <h3 className="text-white text-xl font-light mb-2">{title}</h3> {/* Added font-light */}
    <p className="text-gray-400 text-base font-light leading-relaxed"> {/* Added font-light */}
      {description}
    </p>
  </div>
);

const ExecutiveProfile = ({ name, title, image, bio, expertise }) => (
  <div className="flex flex-col md:flex-row gap-12 md:gap-24 mb-40 last:mb-0">
    {/* Left Column - Name, Image */}
    <div className="w-full md:w-[40%] md:h-[900px]">
      {/* Name and Title First */}
      <div className="mb-8">
        <h2 className="text-3xl md:text-4xl text-white font-light tracking-tight">{name}</h2>
        <h3 className="text-lg md:text-xl text-blue-400 mt-2 font-light">{title}</h3> {/* Added font-light */}
      </div>

      {/* Image Container */}
      <div className="relative bg-[#0A1017] h-[700px]">
        <div className="absolute inset-0 bg-[#0A1017]" />
        <img 
          src={image} 
          alt={name}
          className="relative z-10 w-full h-full object-contain"
        />
      </div>
    </div>

    {/* Right Column - Bio and Expertise */}
    <div className="w-full md:w-[60%] md:h-[900px]">
      <div className="h-full flex flex-col">
        {/* Bio Section */}
        <div className="space-y-6 mb-12">
          {bio.map((paragraph, index) => (
            <p key={index} className="text-gray-300 text-lg font-light leading-relaxed"> {/* Added font-light */}
              {paragraph}
            </p>
          ))}
        </div>

        {/* Expertise Section */}
        <div>
          <h4 className="text-blue-400 text-sm uppercase tracking-wider font-light mb-8">Areas of Expertise</h4> {/* Changed to font-light */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            {expertise.map((item, index) => (
              <ExpertiseCard
                key={index}
                icon={item.icon}
                title={item.title}
                description={item.description}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

const About = () => {
  const executives = [
    {
      name: "Christian Adib",
      title: "Founder and Managing Partner",
      image: "/christian.jpg",
      bio: [
        "With over a decade at the intersection of enterprise technology and business strategy, Christian has established himself as a trusted advisor to Fortune 500 executives navigating the complexities of AI transformation.",
        "His distinctive approach combines deep technical acumen with executive-level strategic vision, enabling organizations to translate AI's potential into measurable business outcomes and sustainable competitive advantages.",
        "Christian holds a B.E. in Mechanical Engineering from the Lebanese American University, an M.S. in Mechanical Engineering from Columbia University, an S.M. in Civil & Environmental Engineering from MIT, and an M.B.A. from the MIT Sloan School of Management."
      ],
      expertise: [
        {
          icon: FileText,
          title: "Enterprise AI Strategy",
          description: "Specialized in developing comprehensive AI transformation strategies for Fortune 500 organizations"
        },
        {
          icon: Users,
          title: "Executive Advisory",
          description: "Trusted advisor to C-suite executives on AI implementation and value realization"
        },
        {
          icon: Award,
          title: "Value Creation",
          description: "Track record of delivering significant ROI through strategic AI initiatives"
        },
        {
          icon: BookOpen,
          title: "Thought Leadership",
          description: "Regular speaker at global technology conferences and executive forums"
        }
      ]
    },
    {
      name: "Bilal Bitar",
      title: "Co-Founder and Managing Partner",
      image: "/bilal.jpg",
      bio: [
        "Bilal brings extensive experience in financial and strategic advisory, leading large teams through enterprise-wide transformation programs in organizations across banking, retail, and technology sectors.",
        "By translating complex innovations into actionable strategies, Bilal helps organizations reimagine processes, unlock untapped potential for long-term growth and competitive advantage, and create tailored solutions that drive tangible outcomes.",
        "Bilal holds a BA in Economics and Political Science from the American University of Beirut and an M.B.A. from INSEAD."
      ],
      expertise: [
        {
          icon: FileText,
          title: "Strategic Advisory",
          description: "Expert in developing and executing enterprise-wide transformation strategies"
        },
        {
          icon: Users,
          title: "Change Management",
          description: "Specialized in facilitating organizational alignment and adoption"
        },
        {
          icon: Award,
          title: "Financial Strategy",
          description: "Deep expertise in financial modeling and value creation frameworks"
        },
        {
          icon: BookOpen,
          title: "Industry Leadership",
          description: "Recognized thought leader in enterprise digital transformation"
        }
      ]
    },
    {
      name: "Cynthia Hajal, Ph.D.",
      title: "Chief Operating Officer",
      image: "/cynthia.jpg",
      bio: [
        "Cynthia brings a unique blend of technical expertise and operational excellence to Fulkerson Advisors, leveraging her deep background in complex systems engineering and data-driven decision making to optimize AI implementation strategies.",
        "Her experience in developing sophisticated analytical models and managing cross-functional research teams translates directly to helping organizations navigate the complexities of AI integration and operational transformation.",
        "Cynthia holds a BA in Economics and BS in Mechanical Engineering from Columbia University, and an SM and PhD in Mechanical Engineering from MIT, where she was recognized as a Rising Star in Mechanical Engineering."
      ],
      expertise: [
        {
          icon: FileText,
          title: "AI Operations Strategy",
          description: "Expert in translating complex AI initiatives into scalable operational frameworks"
        },
        {
          icon: Users,
          title: "Technical Leadership",
          description: "Specialized in bridging technical capabilities with business objectives"
        },
        {
          icon: Award,
          title: "Process Optimization",
          description: "Deep expertise in optimizing organizational processes for AI integration"
        },
        {
          icon: BookOpen,
          title: "Innovation Management",
          description: "Proven track record in leading cutting-edge technical implementations"
        }
      ]
    }
  ];

  return (
    <section id="about" className="py-24 md:py-32 bg-[#0A1017]">
      <div className="container mx-auto px-4 md:px-16">
        {executives.map((executive, index) => (
          <ExecutiveProfile 
            key={index}
            {...executive}
          />
        ))}
      </div>
    </section>
  );
};

export default About;