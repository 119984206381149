import React, { useState, useEffect, useMemo } from 'react';
import { Loader } from 'lucide-react';

const RobotIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="w-6 h-6 md:w-8 md:h-8"
  >
    <rect x="4" y="8" width="16" height="12" rx="2" />
    <path d="M8 8V4h8v4" />
    <line x1="12" y1="2" x2="12" y2="4" />
    <circle cx="8" cy="14" r="1" />
    <circle cx="16" cy="14" r="1" />
    <path d="M10 18h4" />
  </svg>
);

const AITerminal = ({ onClick, isConnecting, error, currentSection }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [cursorVisible, setCursorVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  // Handle mobile detection
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Cursor blink effect
  useEffect(() => {
    const blinkInterval = setInterval(() => {
      setCursorVisible(prev => !prev);
    }, 530);
    return () => clearInterval(blinkInterval);
  }, []);

  // Expand when section changes
  useEffect(() => {
    if (currentSection) {
      setIsExpanded(true);
      const timer = setTimeout(() => setIsExpanded(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [currentSection]);

  // Handle background clicks
  useEffect(() => {
    const handleBackgroundClick = (e) => {
      if (!e.target.closest('.terminal-button')) {
        setIsExpanded(false);
        setIsHovered(false);
      }
    };

    document.addEventListener('click', handleBackgroundClick);
    return () => document.removeEventListener('click', handleBackgroundClick);
  }, []);

  const prompts = useMemo(() => ({
    hero: "init ai_transformation",
    testimonials: "cat client_stories.txt",
    services: "ls -la ./ai_services",
    'case-studies': "./run success_analysis",
    approach: "vim methodology.md",
    team: "whoami --leadership",
    network: "ping expert_network",
    advisory: "ssh advisory_board",
    'blog-section': "tail -f insights.log",
    calendly: "./schedule_meeting.sh"
  }), []);

  const prompt = error 
    ? "error: connection failed" 
    : isConnecting 
      ? "connecting..." 
      : prompts[currentSection] || "ai --help";

  return (
    <div className="pointer-events-auto fixed bottom-16 right-4 md:right-8 z-50">    <button
        onClick={onClick}
        onMouseEnter={() => {
          setIsHovered(true);
          setIsExpanded(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
          if (!currentSection) setIsExpanded(false);
        }}
        disabled={isConnecting}
        style={{ WebkitTapHighlightColor: 'transparent' }}
        className={`
          terminal-button
          relative flex items-center
          h-12 md:h-16 transition-all duration-300 ease-out
          ${isExpanded ? 'w-[80vw] md:w-[400px]' : 'w-[55vw] md:w-[280px]'}
          bg-gradient-to-r from-gray-100/95 to-gray-200/95
          rounded-xl
          hover:shadow-lg
          active:scale-[0.98]
          overflow-hidden
          shadow-lg shadow-black/10
          ${isConnecting ? 'cursor-wait' : 'cursor-pointer'}
          ${error ? 'from-red-100 to-red-200 hover:from-red-200 hover:to-red-300' : ''}
          max-w-[400px]
        `}
      >
        {/* Border Frame */}
        <div className={`
          absolute inset-0 transition-all duration-300
          rounded-xl border border-gray-300/50
          ${isHovered ? 'border-gray-400/50' : ''}
        `} />

        {/* Content Container */}
        <div className="flex items-center gap-2 md:gap-4 px-4 md:px-6">
          {/* Bot Icon */}
          <div className="relative flex items-center justify-center z-10">
            {isConnecting ? (
              <Loader className="w-6 h-6 md:w-8 md:h-8 text-gray-600 animate-spin" />
            ) : (
              <div className="text-gray-600">
                <RobotIcon />
              </div>
            )}
          </div>

          {/* Command text */}
          <div className="flex items-center gap-2 md:gap-3 z-10 min-w-0">
            <span className="font-mono text-base md:text-lg text-emerald-600">
              ${' '}
            </span>
            <span className="font-mono text-base md:text-lg text-gray-600 whitespace-nowrap overflow-hidden text-ellipsis">
              {isExpanded ? prompt : 'chat with AI'}
            </span>
            <span className={`
              w-2 md:w-2.5 h-5 md:h-6 bg-emerald-500 flex-shrink-0
              transition-opacity duration-75
              ${cursorVisible ? 'opacity-100' : 'opacity-0'}
            `} />
          </div>
        </div>

        {/* Scan line effect */}
        <div className="absolute inset-0 pointer-events-none bg-gradient-to-b from-transparent via-white/20 to-transparent opacity-50" />
      </button>

      {/* Error message */}
      {error && (
        <div className="absolute bottom-full mb-4 right-0 w-full p-3 bg-red-50/95 border border-red-200 rounded-lg">
          <p className="text-red-600 text-sm font-mono">{error}</p>
        </div>
      )}
    </div>
  );
};

export default AITerminal;